.income-container {
  width: 90%;
  margin: 0 auto;
  font-family: "Futura", sans-serif;
  max-width: 1200px;
}

.income-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #233ce6;
}

.date-display {
  margin-bottom: 2em;
  color: #333;
}

.income-summary {
  width: 30%;
  padding: 15px 0 0 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 2em;
}

.income-summary h3 {
  margin: 0;
  padding: 0 0 1em 0;
  color: #00796b;
  font-size: 20px;
}

.add-income {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.add-income select,
.add-income input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Futura", sans-serif;
  width: 200px; /* Set a minimum width for inputs */
}

.add-income button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #00796b;
  color: white;
  cursor: pointer;
  font-family: "Futura", sans-serif;
  min-width: 120px; /* Ensure a minimum width for button */
}

.add-income button:hover {
  background-color: #004d40;
}

.message {
  font-size: 14px;
  color: #d32f2f;
  margin-bottom: 20px;
}

.income-list {
  list-style: none;
  padding: 0;
  width: 75%;
}

.income-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
  font-size: 14px;
  color: #333;
}

.income-item span {
  flex: 2;
  font-size: 14px;
  color: #333;
}

.income-item input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Futura', sans-serif;
}

.income-item button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #d32f2f;
  color: white;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Futura', sans-serif;
}

.income-item button:hover {
  background-color: #b71c1c;
}

.income-item button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Design */

/* Small screens (tablets and below) */
@media (max-width: 768px) {
  .income-container {
    width: 100%;
    padding: 0 15px; /* Add padding on smaller screens */
  }

  .income-list {
    width: 100%;
  }

  .add-income select,
  .add-income input {
    width: 100%;
  }

  .add-income button {
    width: 100%;
  }

  .income-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .income-item span {
    margin-bottom: 10px;
    width: 100%; /* Make it take full width */
  }

  .income-item input {
    width: 100%;
    margin-right: 0;
  }

  .income-item button {
    width: 100%;
    margin-top: 10px;
  }
}

/* Extra small screens (mobile phones and below) */
@media (max-width: 480px) {
  .income-container {
    padding: 0 10px; /* Adjust padding for mobile */
  }

  .add-income select,
  .add-income input {
    width: 100%;
    font-size: 16px; /* Larger font size on mobile */
  }

  .add-income button {
    width: 100%;
    font-size: 16px; /* Larger font size on mobile */
  }

  .income-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px; /* Slightly more padding */
  }

  .income-item span {
    margin-bottom: 10px;
    width: 100%;
  }

  .income-item input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .income-item button {
    width: 100%;
    margin-top: 10px;
  }
}
